<template>
  <div style="display: flex; gap: 1%;">
    <b-card
      align="center"
      class="mx-auto"
      style="flex: 1;"
    >
      <rankingMetaAtual />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

import rankingMetaAtual from './rankingMetaAtual.vue'

export default {
  components: {
    BCard,
    rankingMetaAtual,

  },
  props: {
    generalData: {
      type: Number,
      default: () => {},
    },
  },
  data() {
    return {
      recarregaGraficos: false,
      dadosGraficos: false,
    }
  },

  methods: {
    resetGraficos() {
      this.dadosGraficos = false
      this.$refs.show()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
