<template>
  <div>
    <b-row
      align-h="start"
      class="mb-1"
    >
      <b-col cols="1">
        <b-button
          v-b-modal.modalSetDate
          variant="warning"
        >
          Data
        </b-button>
      </b-col>
      <b-col cols="10">
        <h1>Ranking Executivo</h1>
        <h3>{{ formatTimezone(dateRanking) }}</h3>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col md="7">
        <b-row class="mt-1">
          <b-col>
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="20"
                />
              </b-avatar>
              <div class="ml-1 mr-2">
                <h5 class="mb-0">
                  {{ totalPrevisto ? valorBr(totalPrevisto, true) : 0 }}
                </h5>
                <small>Total Previsto</small>
              </div>
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="20"
                />
              </b-avatar>
              <div class="ml-1 mr-1">
                <h5 class="mb-0">
                  {{ totalRemuneracao ? valorBr(totalRemuneracao, true) : 0 }}
                </h5>
                <small>Total Remuneração</small>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-table
      striped
      hover
      :items="employeeData"
      :fields="fields"
      :tbody-tr-class="criteriosParaMetaBatida"
    >
      <template #cell(previsto)="data">
        {{ data.item.previsto ? valorBr(parseFloat(data.item.previsto), true) : 0 }}
      </template>
      <template #cell(remuneracao)="data">
        {{ data.item.remuneracao ? valorBr(parseFloat(data.item.remuneracao), true) : 0 }}
      </template>
      <template #cell(porcentagem_previsto)="data">
        {{ calcularPorcentagemPrevisto(data.item.previsto, data.item.valor_total_acordo).toFixed(2) }}%
      </template>
      <template #cell(porcentagem_remuneracao)="data">
        {{ calcularPorcentagemRealizado(data.item.remuneracao, data.item.realizado).toFixed(2) }}%
      </template>
      <template #cell(data_ultimo_acordo)="data">
        {{ data.item.data_ultimo_acordo ? formatarDataBrasileira(data.item.data_ultimo_acordo) : '' }}
      </template>
    </b-table>
    <b-modal
      id="modalSetDate"
      ref="modalSetDate"
      title="Data Ranking"
      ok-only
      @ok="getMetaExecutivo(dateRanking)"
    >
      <b-row
        align-h="around"
        class="mb-2 mt-2"
      >
        <b-col cols="start">
          <b-row align-h="center">
            <h4>Escolha a data</h4>
          </b-row>
          <b-row>
            <b-form-input
              v-model="dateRanking"
              locale="pt"
              type="date"
            />
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BButton,
  BCol,
  BFormInput,
  BTable,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import axios from '@/../axios-auth'
import firebase from '@/../firebase'

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      totalPrevisto: 0,
      totalRemuneracao: 0,
      employeeData: [],
      dateRanking: '',
      fields: [
        { key: 'nome', label: 'Nome', class: 'text-center', sortable: true },
        { key: 'quantidade_acordos', label: 'Acordos', class: 'text-center', sortable: true },
        { key: 'previsto', label: 'Previsto', class: 'text-center', sortable: true },
        { key: 'porcentagem_previsto', label: '% Previsto', class: 'text-center', sortable: true },
        { key: 'remuneracao', label: 'Remuneração', class: 'text-center', sortable: true },
        { key: 'porcentagem_remuneracao', label: '% Remuneração', class: 'text-center', sortable: true },
        { key: 'data_ultimo_acordo', label: 'Último Acordo', class: 'text-center', sortable: true },
      ],
      metaAcordo: 0,
      metaPrevisto: 0,
      metaPorcentagemPrevisto: 0,
    }
  },
  created() {
    this.dateRanking = this.getFormattedCurrentDate()
    this.getMetaExecutivo(this.dateRanking)
  },
  mounted() {
    firebase.ref('notificacao/novoAcordo').on('value', snapshot => {
      const novoAcordo = snapshot.val()
      if (novoAcordo) {
        this.getMetaExecutivo(this.dateRanking)
        firebase.ref('notificacao/novoAcordo').set(false)
      }
    })
  },
  methods: {
    getMetaExecutivo(date) {
      this.employeeData = []
      this.totalRemuneracao = 0
      this.totalPrevisto = 0
      this.metaAcordo = 0
      this.metaPrevisto = 0
      this.metaPorcentagemPrevisto = 0
      const body = {
        dia: date,
      }
      axios.post('api/v1/executivo/faturamento/diario', body, {
      })
        .then(res => {
          res.data.dados.map(item => {
            if (!item.indicadores && item.indicadores !== null) {
              this.employeeData.push(item)
              this.totalRemuneracao += parseFloat(item.remuneracao)
              this.totalPrevisto += parseFloat(item.previsto)
            } else if (item.indicadores !== null) {
              this.metaAcordo = item.indicadores.acordos
              this.metaPrevisto = parseFloat(item.indicadores.valor)
              this.metaPorcentagemPrevisto = parseFloat(item.indicadores.percentual)
            }
          })
          // ordena o array employeeData com base no valor da porcentagem de meta
          this.employeeData.sort((a, b) => b.previsto - a.previsto)
        })
        .catch()
    },
    kFormatter,
    getFormattedCurrentDate() {
      const today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    },
    calcularPorcentagemRealizado(remuneracao, realizado) {
      const remuneracaoFloat = parseFloat(remuneracao || 0)
      const realizadoFloat = parseFloat(realizado || 0)
      if (remuneracaoFloat === 0 && realizadoFloat === 0) {
        return 0
      }
      return (remuneracaoFloat / realizadoFloat) * 100
    },
    calcularPorcentagemPrevisto(previsto, totalAcordo) {
      return (parseFloat(previsto || 0) / (parseFloat(totalAcordo || 0) - parseFloat(previsto || 0))) * 100
    },
    formatarDataBrasileira(dataString) {
      const data = new Date(dataString)
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'America/Sao_Paulo',
      }
      return new Intl.DateTimeFormat('pt-BR', options).format(data)
    },
    criteriosParaMetaBatida(item) {
      if (item.quantidade_acordos >= this.metaAcordo && this.checkMetaPrevisto(item.previsto) && this.checkMetaPorcentagemPrevisto(item)) {
        return 'table-success'
      }
      return ''
    },
    checkMetaPrevisto(previsto) {
      if (parseFloat(previsto) >= this.metaPrevisto) return true
      return false
    },
    checkMetaPorcentagemPrevisto(item) {
      if (this.calcularPorcentagemPrevisto(item.previsto, item.valor_total_acordo) >= this.metaPorcentagemPrevisto) return true
      return false
    },
  },
}
</script>
<style scoped>
  .progress-container {
    position: relative;
    height: 6px;
  }
  .progress-text {
    position: absolute;
    right: 0;
    top: -1rem;
    font-size: 0.8rem;
    color: #fff;
    background-color: #007aff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
  }
  .col-avatar {
    width: 120px;
  }
  .col-meta {
    width: 120px;
  }
  .col-progress {
    width: 200px;
  }
  .margin-porcent {
    margin-top: 4.5%
  }
  .gold-border {
    border: 2px solid gold;
  }
  .silver-border {
    border: 2px solid silver;
  }
  .bronze-border {
    border: 2px solid #cd7f32;
  }
  .position-relative {
    position: relative;
  }
  .crown-icon {
    position: absolute;
    top: -20px; /* Ajuste o valor conforme necessário para alinhar verticalmente */
    left: 48%; /* Ajuste o valor conforme necessário para alinhar horizontalmente */
    transform: translateX(-50%);
  }
</style>
